<template>
  <div id="Register">
    <div class="PuTong">
      <div class="RegisterBox">
        <div class="title">
          <div></div>
        </div>
        <el-input class="danwei" type="text" v-model="User.account" placeholder="请输入单位名称" @keyup.enter.native="btn()"
          @input="alter()"></el-input>
        <el-input class="tongyishehuixingyongdaima" type="text" v-model="User.unecode" placeholder="请输入社会统一信用代码"
          @keyup.enter.native="btn()" @input="alter()"></el-input>

        <el-input class="dianhua" v-model="User.tel" placeholder="请输入电话号码" @keyup.enter.native="btn()"
          @input="alter()"></el-input>
        <el-input class="mima" type="password" v-model="User.password" show-password placeholder="请输入密码"></el-input>

        <el-button type="text" style="color: white; position: absolute; top: 27.6875rem; left: 5.0625rem"
          @click="GoLogin()">返回登录页面</el-button>

        <div style="position: absolute; left: 15.875rem; top: 28.4375rem">
          <!-- <el-radio style="color: white" v-model="radio" label="1">企业注册</el-radio> -->
          <!-- <el-radio style="color: white" v-model="radio" label="2">第三方注册</el-radio> -->
        </div>

        <div class="bottom" @keyup.enter="btn()">
          <div class="may" v-show="show" @click="btn()">注册</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    // 密码校验
    var validatePass = (rule, value, callback) => {
      if (value.trim() == "") {
        callback(new Error("请输入密码"));
      } else {
        // setTimeout(() => {
        let pwdRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^&*)(_+}{|:?><]).{8,18}$/;
        if (!pwdRegex.test(value)) {
          callback(new Error("8-18个字符,必须包含大小写字母和数字的组合,可以包含特殊符号\~!@#^*_"));
          // new Error("8-18个字符,必须包含大小写字母和数字的组合,可以包含特殊符号\~!@#^*_")
        } else {
          callback();
        }
        // });
      }
    };
    return {
      User: {
        account: "",
        unecode: "",
        tel: "",
        password: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入企业账号", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "用户名长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        unecode: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "blur",
          },
        ],
        tel: [{ required: true, message: "请输入电话号码", trigger: "blur" }],
        // password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
      },
      show: true,
      // city: Global.aqi,
      type: null,

      radio: "1",
    };
  },
  created() {
    switch (this.city) {
      default:
        this.type = "PuTong";
        break;
    }
  },
  methods: {
    GoLogin() {
      console.log("GoLogin");
      //   this.$router.push({ path: "/register" });
      this.$router.go(-1);
    },
    btn() {
      if(this.User.account.trim()==''){
        this.$message({
          message: '单位名称长度需在 3 到 5 个字符',
          type: "error",
        });
        this.User.account = '';
        return;
      }
      if(this.User.unecode.trim()==''){
        this.$message({
          message: '请输入社会统一信用代码',
          type: "error",
        });
        this.User.unecode = '';
        return;
      }
      if(this.User.tel.trim()==''){
        this.$message({
          message: '请输入电话号码',
          type: "error",
        });
        this.User.tel = '';
        return;
      }
      let pwdRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^&*)(_+}{|:?><]).{8,18}$/;
      if (!pwdRegex.test(this.User.password)) {
        this.$message({
          message: '密码为8-18个字符,必须包含大小写字母和数字的组合,可以包含特殊符号\~!@#^*_',
          type: "error",
        });
        // callback(new Error("8-18个字符,必须包含大小写字母和数字的组合,可以包含特殊符号\~!@#^*_"));
        // new Error("8-18个字符,必须包含大小写字母和数字的组合,可以包含特殊符号\~!@#^*_")
      } else {
        console.log("radio", this.radio);
        let url = this.radio == 1 ? "https://eplogin.bjzzyh.com/enterprise/api/user/register" : "http://49.4.83.25:9084/thirdParty/api/thirdPartyUser/thirdPartyRegister";
        console.log("url", url);
        // 121注册
        // let url = this.radio == 1 ? "http://121.37.90.200:9086/enterprise/api/user/register" : "http://121.37.90.200:9084/thirdParty/api/thirdPartyUser/thirdPartyRegister";

        axios.post(url, this.User).then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.$router.replace({ path: "/" });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          console.log(res);
        });
      }
    },
    alter(e) {
      console.log(11111111, e);
      // if (e !== undefined && e.length > 0) {
      //   this.show = true;
      // } else {
      //   this.show = false;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
#Register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;

  .RegisterBox {
    margin-top: 8.125rem;
    width: 32.8125rem;
    height: 36.0625rem;
    background: url("@/assets/register.png") no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
      margin-top: -5rem;

      div {
        width: 27.5rem;
        height: 2.1875rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // 一般背景图片
  .PuTong {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url("@/assets/background.jpg") no-repeat;
    background-size: 100% 100%;
    /* 拉伸图片以完全填满容器 */
    background-position: center;
  }

  // /deep/ input {
  //   background-color: transparent !important;
  // }
}

::v-deep.danwei {
  position: absolute;
  top: 8.6875rem;
  left: 5rem;
}

::v-deep.tongyishehuixingyongdaima {
  position: absolute;
  top: 13.875rem;
  left: 5rem;
}

::v-deep.dianhua {
  position: absolute;
  top: 19rem;
  left: 5rem;
}

::v-deep.mima {
  position: absolute;
  top: 24.125rem;
  left: 5rem;
  width: 23.5625rem;
}

::v-deep.el-input__inner::placeholder {
  font-size: 1.125rem;
}

el-input {
  background-color: transparent;
}

::v-deep.el-form-item {
  margin-bottom: 0;
}

::v-deep .el-input__inner {
  background-color: transparent !important;
  outline: 0;
}

::v-deep.el-input__inner:focus {
  border-color: transparent;
  outline: 0;
}

::v-deep .el-input__inner:hover {
  border-color: transparent;
}

::v-deep .el-input__inner {
  border: none;
  color: white;
}

input:focus {
  outline: none;
}

input {
  background-color: transparent;
}

.may {
  width: 23.1875rem;
  height: 4rem;
  line-height: 4rem;
  background: url("@/assets/may.png") no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1.375rem;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 30.125rem;
  left: 5.25rem;
  text-align: center;
}

::v-deep.el-input {
  width: 80%;
  height: 2.5rem;
  line-height: 2.5rem;

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #ededed !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent !important;
    background-image: none !important;
    transition: background-color 50000s ease-in-out 0s !important;
  }

  input {
    background-color: transparent !important;
  }
}

::v-deep.el-input__inner {
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: transparent;
  border-bottom: none !important;
  color: rgb(255, 255, 255);
  padding: 0;
  opacity: 0.8;

  div {
    height: 100%;
    text-align: center;
  }
}

::v-deep.el-input__inner::placeholder {
  font-size: 1.125rem;
}

::v-deep.el-form-item {
  margin-bottom: 0;
}

::v-deep.el-input__inner:focus {
  border-color: transparent;
  outline: 0;
}

::v-deep .el-input__inner:hover {
  border-color: transparent;
}

::v-deep .el-input__inner {
  border: none;
  color: white;
}

.el-input {
  font-size: 18px;
}
</style>
