<template>
  <div class="login">
    <div style="width: 525px; height: 459px; position: relative">
      <img style="position: absolute; left: 0" src="../assets/login.png" />
      <el-input
        style="position: absolute; left: 118px; top: 135px; background-color: transparent; border: none; width: 340px"
        type=""
        v-model="account"></el-input>
      <el-input
        show-password
        style="position: absolute; left: 118px; top: 217px; background-color: transparent; border: none; width: 340px"
        type=""
        v-model="password"></el-input>
      <div style="position: absolute; top: 289px; left: 70px; display: flex">
        <el-radio style="color: white" v-model="radio" label="1">管理员登录</el-radio>
        <el-radio style="color: white" v-model="radio" label="2">企业登录</el-radio>
      </div>
      <div
        class="login-button"
        style="
          position: absolute;
          top: 337px;
          left: 82px;
          font-size: 20px;
          width: 371px;
          height: 64px;
          line-height: 64px;
          border-radius: 5px;
          color: white;
          cursor: pointer;
        "
        @click="login">
        登录
      </div>
      <el-button
        type="text"
        style="position: absolute; top: 403px; left: 249px; width: 30px; height: 10px; line-height: 10px; border-radius: 5px; color: white; cursor: pointer"
        @click="toRegistry"
        >注册</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      account: "",
      password: "",
      radio: "1",


      // 49ip与跳转地址
      IP:"49.4.83.25",
      gl:"gl.bjzzyh.com",
      qy:"qy.bjzzyh.com",

      // 121ip与跳转地址
      // IP:"121.37.90.200",
      // gl:"gl.qyesg.cn",
      // qy:"qy.qyesg.cn",
    };
  },
  methods: {
    login() {
      console.log("radio", this.radio, axios);
      // return
      if (this.radio === "1") {
        axios
          .post('https://login.bjzzyh.com/oa/api/employee/Login', {
            OE_Account: this.account,
            OE_Password: this.password,
          })
          .then((res) => {
            console.log("lwj335", res.data.data.Token);
            if (res.data.code == 1) {
              const redirectUrl = `https://${this.gl}/#/index?token=${res.data.data.Token}&LoginData=${JSON.stringify(res.data.data)}`;
              // 执行页面跳转
              window.location.href = redirectUrl;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
      if (this.radio === "2") {
        axios
          .post('https://eplogin.bjzzyh.com/enterprise/api/user/login', {
            account: this.account,
            password: this.password,
          })
          .then((res) => {
            console.log("lwj335", res);
            if (res.data.code == 1) {
              // 跳转企业端这里http不用带s，具体为啥不太清楚，但是带了s会跳转到视频会议，可能是映射有问题？
              const redirectUrl = `http://${this.qy}/#/home?token=${res.data.data.token}&setcompanyData=${JSON.stringify(res.data.data)}`;

              // 执行页面跳转
              window.location.href = redirectUrl;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
    },
    toRegistry() {
      this.$router.push("/registry");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login {
  height: 100%;
  background: url(../assets/background.jpg);
  background-size: 100% 100%; /* 拉伸图片以完全填满容器 */
  display: flex;
  justify-content: center;
  align-items: center;
}

input:focus {
  outline: none;
}

input {
  background-color: transparent;
}

label {
  // margin-left: 10px;
  width: 200px;
}

label:first-child {
  // margin-left: 0;
  width: 200px;
  margin-right: 0;
}

.login-button {
  background: url(../assets/may.png);
}

::v-deep.el-input {
  width: 80%;
  height: 2.5rem;
  line-height: 2.5rem;

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #ededed !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent !important;
    background-image: none !important;
    transition: background-color 50000s ease-in-out 0s !important;
  }

  input {
    background-color: transparent !important;
  }
}

::v-deep.el-input__inner {
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: transparent;
  border-bottom: none !important;
  color: rgb(255, 255, 255);
  padding: 0;
  opacity: 0.8;

  div {
    height: 100%;
    text-align: center;
  }
}

::v-deep.el-input__inner::placeholder {
  font-size: 1.125rem;
}

::v-deep.el-form-item {
  margin-bottom: 0;
}

::v-deep.el-input__inner:focus {
  border-color: transparent;
  outline: 0;
}

::v-deep .el-input__inner:hover {
  border-color: transparent;
}

::v-deep .el-input__inner {
  border: none;
  color: white;
}
</style>
