import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Login from '@/views/Login.vue';
import Registry from '@/views/Registry.vue';

const routes = [
    { path: '/', redirect: '/login' }, // 添加重定向路由
    { path: '/login',  component: Login },
    { path: '/registry', component: Registry }
];

const router = new VueRouter({
  routes // 使用定义的路由
});

export default router;